import { Icon, Row, Typography, Utils } from '@care/web-ui';
import { ComboBoxItem, ComboBoxProps } from '@care/web-ui/lib/components/ComboBox/ComboBox';
import React, { useContext, useEffect, useMemo } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import ComboBoxField from 'src/component/Field/ComboBoxField';
import InputField from 'src/component/Field/InputField';
import { appScreenRoutingOptions } from 'src/constant/appScreenRoutingList.constant';
import { AppHomeScreenContext } from 'src/context/AppHomeScreen.context';
import { CategoryContext } from 'src/context/Category.context';
import { UIRoutingType } from 'src/enum/routingType.enum';
import { useFormatCatalogRoutings } from 'src/hooks/catalog/useFormatCatalogRoutings';
import { useQueryCatalogByKeyword } from 'src/hooks/catalog/useQueryCatalogByKeyword';
import { CatalogStore, useCatalogStore } from 'src/zustand-store/catalog/Catalog.store';
import { useFormatEcCategoryRoutings } from 'src/hooks/category/useFormatEcCategoryRoutings';
import { useQueryEcCategoryList } from 'src/hooks/category/useQueryEcCategoryList';
import { AppScreenRouting } from 'src/enum/appScreenRouting.enum';
import { useQuerySimpleMembershipPlanList } from 'src/hooks/membership/useQuerySimpleMembershipPlanList';
import { useSimpleMembershipPlanListStore } from 'src/zustand-store/membership/SimpleMembershipPlanList.store';

interface Props {
  control: Control<any>;
  onSetValue: UseFormSetValue<any>;
  disabled?: boolean;
  customRoutings?: ComboBoxItem[];
}

const {
  CommonUtil: { debounce },
} = Utils;

export const routings: ComboBoxItem[] = [
  {
    text: 'Speciality List',
    value: UIRoutingType.SpecialtyList,
  },
  {
    text: 'Service List',
    value: UIRoutingType.ProductList,
  },
  {
    text: 'Service Detail',
    value: UIRoutingType.ProductDetail,
  },
  {
    text: 'Product List',
    value: UIRoutingType.EcProductList,
  },
  {
    text: 'Product Detail',
    value: UIRoutingType.EcProductDetail,
  },
  {
    text: 'App Screen',
    value: UIRoutingType.AppScreen,
  },
  {
    text: 'External Link',
    value: UIRoutingType.External,
  },
  {
    text: 'Custom Internal Link',
    value: UIRoutingType.CustomInternalLink,
  },
];

const dataTestIdPrefix = 'routing-path';

export const RoutingPath = ({ control, onSetValue, disabled, customRoutings }: Props) => {
  const { catRoutings, loadGroupCats } = useContext(CategoryContext);
  const { productRoutings, loadProductRouting, specialtyRoutings, loadSpecialtyRoutings } =
    useContext(AppHomeScreenContext);

  // Catalog routing data
  const catalogLoading = useCatalogStore((store: CatalogStore) => store.isLoading);
  const { catalogRoutings } = useFormatCatalogRoutings();
  const { queryCatalogByKeyword } = useQueryCatalogByKeyword();

  // EC Category routing data
  const { ecCategoryRoutings } = useFormatEcCategoryRoutings();
  const { queryEcCategoryList } = useQueryEcCategoryList();

  useQuerySimpleMembershipPlanList();
  const { data: membershipPlanList, isLoading: isMembershipPlanListLoading } = useSimpleMembershipPlanListStore();

  const membershipPlanOptions = useMemo(() => {
    return (membershipPlanList || []).map(({ id, displayName }) => ({
      text: displayName,
      value: id,
    }));
  }, [membershipPlanList]);

  const [routingType, routingValue] = useWatch({
    control,
    name: ['routingType', 'routingValue'],
  });

  const refDebounceCatalogSearch = React.useRef({
    action: debounce((value: string) => {
      queryCatalogByKeyword(value);
    }, 500),
  });

  useEffect(() => {
    return () => {
      refDebounceCatalogSearch.current.action.cancel();
    };
  }, []);

  // TODO: remove use effect
  useEffect(() => {
    if (routingType === UIRoutingType.ProductList) {
      !catRoutings.length && loadGroupCats();
    } else if (routingType === UIRoutingType.ProductDetail) {
      !productRoutings.length && loadProductRouting();
      loadProductRouting();
    } else if (routingType === UIRoutingType.EcProductList) {
      queryEcCategoryList();
    } else if (routingType === UIRoutingType.EcProductDetail) {
      queryCatalogByKeyword();
    } else if (routingType === UIRoutingType.SpecialtyList) {
      !specialtyRoutings.length && loadSpecialtyRoutings();
    }
  }, [routingType]);

  const renderComboboxField = ({ className, ...rest }: ComboBoxProps) => (
    <ComboBoxField
      control={control}
      name="routingValue"
      placeholder="Search"
      showSearch
      suffix={<Icon name="Search" type="outline" className="text-neutral-70" />}
      className={`[&_.suffix_svg:nth-child(2)]:hidden ${className}`}
      disabled={disabled}
      onChange={() => onSetValue('membershipId', '')}
      customAttr={{ dataTestId: `${dataTestIdPrefix}-routing-value-combobox` }}
      {...rest}
    />
  );

  const renderDropdownType = () => {
    switch (routingType) {
      case UIRoutingType.ProductList:
        return renderComboboxField({ items: catRoutings });
      case UIRoutingType.AppScreen:
        return (
          <div className="flex flex-col gap-4">
            {renderComboboxField({ items: appScreenRoutingOptions })}
            {routingValue === AppScreenRouting.WELLNESS && (
              <ComboBoxField
                control={control}
                name="membershipId"
                placeholder="Search Membership Plan"
                showSearch
                suffix={<Icon name="User" type="outline" className="text-neutral-70" />}
                className={`[&_.suffix_svg:nth-child(2)]:hidden`}
                customAttr={{ dataTestId: `${dataTestIdPrefix}-membership-id-combobox` }}
                disabled={disabled || isMembershipPlanListLoading}
                items={membershipPlanOptions}
              />
            )}
          </div>
        );
      case UIRoutingType.ProductDetail:
        return renderComboboxField({ items: productRoutings });
      case UIRoutingType.SpecialtyList:
        return renderComboboxField({ items: specialtyRoutings });
      case UIRoutingType.EcProductList:
        return renderComboboxField({ items: ecCategoryRoutings });
      case UIRoutingType.EcProductDetail:
        return renderComboboxField({
          items: catalogRoutings,
          loading: catalogLoading,
          onSearch: refDebounceCatalogSearch.current.action,
        });
      default:
        return (
          <InputField
            control={control}
            name="routingValue"
            placeholder="Input URL"
            disabled={disabled}
            customAttr={{ dataTestId: `${dataTestIdPrefix}-routing-value-input` }}
          />
        );
    }
  };

  const resetFields = () => {
    onSetValue('routingValue', '');
    onSetValue('membershipId', '');
  };

  return (
    <div>
      <Typography display="block" className="care-label">
        Routing
      </Typography>
      <Row gutter={16} spans={[8, 16]}>
        <ComboBoxField
          items={customRoutings || routings}
          control={control}
          name="routingType"
          onChange={resetFields}
          disabled={disabled}
          customAttr={{ dataTestId: `${dataTestIdPrefix}-routing-type-combobox` }}
        />
        {renderDropdownType()}
      </Row>
    </div>
  );
};
