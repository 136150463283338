import { Modal } from '@care/web-ui';
import React, { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { CreatePromoCodeModelDTO } from 'src/model/dto/CreatePromoCode.request';
import { EditPromoCodeModelDTO } from 'src/model/dto/EditPromoCode.request';

import { PromoCodeSummaryResDTO } from 'src/model/dto/PromoCode.response';
import { PromoCodeDetailModelDTO } from 'src/model/dto/PromoCodeDetail.response';
import PromoCodeAPI from 'src/service/PromoCodeAPI';
import ProductAPI from 'src/service/ProductAPI';
import { ProductModel } from 'src/model/Product.model';
import { ProductFilter } from './ProductList.context';
import { ProductStatus } from 'src/enum/ProductStatus';
import LocationAPI from 'src/service/LocationAPI';
import { LocationModel } from 'src/model/Location.model';

class ContextState {
  summary: PromoCodeSummaryResDTO;

  loading: boolean;

  promoCode!: PromoCodeDetailModelDTO;

  loadDetail!: (id: string) => Promise<any>;

  addPromoCode: (data: CreatePromoCodeModelDTO) => Promise<boolean>;

  editPromoCode: (id: string, data: EditPromoCodeModelDTO) => Promise<boolean>;

  deletePromoCode: (id: string) => Promise<boolean>;

  getProductsByOrgId: (orgId: string) => Promise<ProductModel[]>;

  getLocationsByOrgId: (orgId: string) => Promise<LocationModel[]>;
}

export const PromoCodeActionContext = React.createContext(new ContextState());

const PromoCodeActionProvider = ({ children }: PropsWithChildren) => {
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<PromoCodeSummaryResDTO>();
  const [promoCode, setPromoCode] = useState<PromoCodeDetailModelDTO>(new PromoCodeDetailModelDTO());

  const loadDetail = async (id: string): Promise<any> => {
    try {
      setLoading(true);
      const body = await PromoCodeAPI.getPromoCodeById(id);
      setPromoCode(body);
      return true;
    } catch (error) {
      Modal.confirm({ title: 'Error', content: error.message || error, type: 'error' });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const getSummary = async () => {
    const res = await PromoCodeAPI.getPromoCodeSummary();
    setSummary(res);
  };

  const addPromoCode = async (data: CreatePromoCodeModelDTO): Promise<boolean> => {
    try {
      setLoading(true);
      await PromoCodeAPI.addPromoCode(data);
      getSummary();
      return true;
    } catch (error) {
      Modal.confirm({ title: 'Error', content: error.message || error, type: 'error' });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const editPromoCode = async (id: string, data: any): Promise<boolean> => {
    try {
      setLoading(true);
      await PromoCodeAPI.editPromoCode(id, data);
      getSummary();
      return true;
    } catch (error) {
      Modal.confirm({ title: 'Error', content: error.message || error, type: 'error' });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const deletePromoCode = async (id: string): Promise<boolean> => {
    try {
      setLoading(true);
      await PromoCodeAPI.deletePromoCode(id);
      getSummary();
      return true;
    } catch (error) {
      Modal.confirm({ title: 'Error', content: error.message || error, type: 'error' });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const getProductsByOrgId = async (orgId: string): Promise<ProductModel[]> => {
    const productFilter: ProductFilter = {
      status: [ProductStatus.Active],
      organizationIds: [orgId],
    };
    try {
      setLoading(true);
      const res = await ProductAPI.getProducts(productFilter, 1, 1000);
      return res.data;
    } catch (error) {
      Modal.confirm({ title: 'Error', content: error.message || error, type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const getLocationsByOrgId = async (orgId: string): Promise<LocationModel[]> => {
    try {
      setLoading(true);
      const res = await LocationAPI.getLocations({ orgIds: [orgId] }, 1, 1000);
      return res.data;
    } catch (error) {
      Modal.confirm({ title: 'Error', content: error.message || error, type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSummary();
  }, []);

  return (
    <PromoCodeActionContext.Provider
      value={{
        summary,
        loading,
        addPromoCode,
        editPromoCode,
        deletePromoCode,
        loadDetail,
        promoCode,
        getProductsByOrgId,
        getLocationsByOrgId,
      }}
    >
      {children}
    </PromoCodeActionContext.Provider>
  );
};

export default PromoCodeActionProvider;
