import React, { useMemo, useState } from 'react';
import { Typography, Table, Icon } from '@care/web-ui';
import Spacer from 'src/component/Spacer/Spacer';
import { pageSizeOptions, PAGE_SIZE_PER_MODULE } from 'src/constant/Pagination.constant';
import { useOrdersContext } from 'src/context/Orders.context';
import { OrderSortBy } from 'src/enum/orderSortBy.enum';

import { getColumns } from './columns';

import './OrdersTableStyle.scss';
import { OrderType } from 'src/enum/OrderStatus';
import { OrderDetailModel } from 'src/model/OrderDetail.model';
import OrderLineItem from '../order-line-item/OrderLineItem';
import { useOrderDetailContext } from 'src/context/OrderDetail.context';

const sortParams = {
  descend: OrderSortBy.Newest,
  ascend: OrderSortBy.Oldest,
};

export const OrdersTable = () => {
  const { data: orders, loading, pagination, setPagination, filter, setFilter } = useOrdersContext();
  const { loadDetail } = useOrderDetailContext();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const handleTableChange: React.ComponentProps<typeof Table>['onChange'] = (
    newPagination,
    filterTable,
    newSortBy: any,
  ) => {
    const { orderType: orderTypes } = filterTable;

    setPagination({
      ...pagination,
      pageIndex: newPagination.current,
      pageSize: newPagination.pageSize,
    });
    setFilter({
      ...filter,
      orderTypes: orderTypes as OrderType[],
      sortBy: sortParams[newSortBy.order],
    });
  };

  const { sortBy, orderTypes: orderTypesFilter } = filter;
  const columns = useMemo(() => getColumns({ sortBy, orderTypes: orderTypesFilter }), [sortBy, orderTypesFilter]);

  return (
    <div className="orders-table">
      <Table
        loading={loading}
        columns={columns as any}
        onChange={handleTableChange}
        data={orders}
        renderEmpty={
          <div>
            <img src="/refunds.png" style={{ width: 148 }} alt="orders" />
            <Spacer />
            <Typography fontCategory="serif" level={7}>
              There are no order yet
            </Typography>
          </div>
        }
        pagination={{
          defaultPageSize: PAGE_SIZE_PER_MODULE.REFUNDS,
          showSizeChanger: true,
          pageSizeOptions,
          total: pagination.total,
          current: pagination.pageIndex,
          pageSize: pagination.pageSize,
          onChange: (pageIndex: number, pageSize: number) => setPagination({ pageIndex, pageSize }),
        }}
        expandable={{
          expandedRowRender: (record: OrderDetailModel) => (
            <OrderLineItem
              id={record.id}
              orderType={record.orderType as OrderType}
              paymentStatus={record.paymentStatus}
            />
          ),
          expandIcon: ({ expanded, onExpand, record }) => {
            const { orderType }: OrderDetailModel = record;
            switch (orderType) {
              case OrderType.Product:
                return (
                  <Icon
                    className="cursor-pointer"
                    name={expanded ? 'DownSmall' : 'RightSmall'}
                    type="outline"
                    onClick={() => onExpand(record, null)}
                  />
                );
              default:
                break;
            }
          },
          onExpand: (expanded: boolean, record: OrderDetailModel) => {
            const expandedKeys = [];
            if (expanded) {
              expandedKeys.push(record.id);
              if (record.id) {
                loadDetail(record.id);
              }
            }
            setExpandedRowKeys(expandedKeys);
          },
          expandedRowKeys,
        }}
      />
    </div>
  );
};
