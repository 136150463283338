import React from 'react';
import { Switch } from '@care/web-ui';
import { SwitchProps } from '@care/web-ui/lib/components/Switch/Switch';
import Field from './Field';

interface Props extends SwitchProps {
  name: string;
  control: any;
  onBeforeChange?: (value: boolean, func: any) => void;
}

const SwitchField = ({ name, control, onChange: onInputChange, onBeforeChange, customAttr, ...rest }: Props) => (
  <Field
    name={name}
    control={control}
    customAttr={customAttr}
    render={({ onChange, value }) => {
      return (
        <Switch
          {...rest}
          value={Boolean(value)}
          customAttr={customAttr}
          onChange={(newValue) => {
            if (onBeforeChange) {
              onBeforeChange(newValue as any, onChange);
            } else {
              onChange(newValue);
            }

            if (onInputChange) {
              onInputChange(newValue);
            }
          }}
        />
      );
    }}
  />
);

export default SwitchField;
