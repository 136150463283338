/* eslint-disable tailwindcss/no-custom-classname */
import React from 'react';
import { Divider, Row, Tag } from '@care/web-ui';
import { userContactStatusTag, userEmailStatusTag, userStatusTag } from 'src/constant/UserStatusTag.constant';
import { UserModel } from 'src/model/User.model';
import UserInfoItem from './UserInfoItem';
import { useUserData } from './useUserData';

interface UserModalContentProps {
  user: UserModel;
}

const ModalContent = (props: UserModalContentProps) => {
  const { user } = props;
  const {
    profileVerifiedStatus,
    emailActivatedStatus,
    contactActivatedStatus,
    formattedDateOfBirth,
    lastTimeLoginFail,
    lastTimeUpdatePassword,
    socialTypes,
  } = useUserData(user);

  return (
    <div>
      <p className="mb-4 font-serif text-[32px] font-medium leading-9">{user.name || '-'}</p>
      <Row gutter={24} spans={[12, 12]}>
        <div className="left-column">
          <div className="profile-info">
            {/* Start Profile information */}
            <div className="mb-4">
              <span className="text-xl font-bold leading-7">Profile information</span>
              <Divider className="text-neutral-20" />
            </div>
            <div className="mb-4">
              <p className="mb-0 text-base text-primary-6">Status</p>
              <Tag text={userStatusTag[profileVerifiedStatus].label} type={userStatusTag[profileVerifiedStatus].type} />
            </div>
            <UserInfoItem title="Role" value={user.role} />
            <UserInfoItem title="Care ID" value={user.careId} />
            <UserInfoItem title="Legacy RC user ID" value={user._id} />
            <div className="mb-4 text-base">
              <p className="mb-0 text-primary-6">ID Number</p>
              <p className="mb-0 font-bold">{user.idNum ?? '-'}</p>
              <p>{user.idType ?? '-'}</p>
            </div>
            <UserInfoItem title="Passport" value={user.passport} />
            {/* End Profile information */}
            {/* Start Contacts */}
            <div className="mb-4">
              <span className="text-xl font-bold leading-7">Contacts</span>
              <Divider className="text-neutral-20" />
            </div>
            <div className="mb-4">
              <p className="mb-0 text-base text-primary-6">Email</p>
              <p className="mb-0 text-base">{user.email ?? '-'}</p>
              <Tag
                text={userEmailStatusTag[emailActivatedStatus].label}
                type={userEmailStatusTag[emailActivatedStatus].type}
              />
            </div>
            <div className="mb-4">
              <p className="mb-0 text-base text-primary-6">Contact</p>
              <p className="mb-0 text-base">{user.contact ?? '-'}</p>
              <Tag
                text={userContactStatusTag[contactActivatedStatus].label}
                type={userContactStatusTag[contactActivatedStatus].type}
              />
            </div>
            <div className="mb-4">
              <p className="mb-0 text-base text-primary-6">Social logins</p>
              {socialTypes.map((tag, index) => (
                <Tag className="mb-1 mr-1" key={index} text={tag.label} type={tag.type} />
              ))}
            </div>
            {/* End Contacts */}
          </div>
        </div>
        <div className="right-column">
          {/* Start Personal Info */}
          <div className="mb-4">
            <span className="text-xl font-bold leading-7">Personal Info</span>
            <Divider className="text-neutral-20" />
          </div>
          <UserInfoItem title="Date of birth" value={formattedDateOfBirth} />
          <UserInfoItem title="Sex" value={user.gender} />
          <UserInfoItem title="Race" value={user.race} />
          <UserInfoItem title="Nationality" value={user.nationality} />
          {/* End Personal Info */}
          {/* Start  Status */}
          <div className="mb-4">
            <span className="text-xl font-bold leading-7">Status</span>
            <Divider className="text-neutral-20" />
          </div>
          <UserInfoItem title="Unsuccess login times" value={user.unsuccessLoginTimes} />
          <UserInfoItem title="Last time login fail" value={lastTimeLoginFail} />
          <UserInfoItem title="Last time update password" value={lastTimeUpdatePassword} />
          {/* End Status */}
        </div>
      </Row>
    </div>
  );
};

export default ModalContent;
