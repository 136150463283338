import React, { useEffect, useMemo } from 'react';
import { Button, Paragraph, Row, Table, Tag, Text, Tooltip, Typography, Utils } from '@care/web-ui';

import { FilteredDropdown, ColumnFilterItem } from 'src/component/FilteredDropdown/FilteredDropdown';
import { useProductListContext } from 'src/context/ProductList.context';
import { ProductModel } from 'src/model/Product.model';
import { ProductStatus } from 'src/enum/ProductStatus';
import { useOrganizationListContext } from 'src/context/OrganizationList.context';
import { useProductActionContext } from 'src/context/ProductAction.context';
import { pageSizeOptions, PAGE_SIZE_PER_MODULE } from 'src/constant/Pagination.constant';
import { OrganizationModel } from 'src/model/OrganizationModel';
import { renderOrgFilterColumn } from 'src/util/FilterColumn';
import { ProductType } from 'src/enum/ProductType.enum';
import { Sorter } from 'src/util/createPagingDataContext';
import { ProductSortBy } from 'src/enum/ProductSortBy.enum';
import { productStatusToTag } from 'src/constant/ProductStatusTag.constant';
import ProductsCategory from '../products-category';
import { useQueryCategoryList } from 'src/hooks/category/useCategoryList';
import { TableColumn } from 'libs/care-ui/lib/components/Table/Table';
import { useGetProductSelected, useHandleFormattedDataProductList } from './ProductTable.actions';
import { ProductListMutationStore, useStore } from 'src/zustand-store/product-list/ProductListMutation.store';
import { shallow } from '@care/web-ui/lib/store';
import { autoRemoveDataIfSelectedAll } from 'src/util/product.util';

const {
  CommonUtil: { pick },
} = Utils;

export const statusOptions: ColumnFilterItem[] = Object.keys(ProductStatus).map((key) => ({
  text: productStatusToTag[ProductStatus[key]].label,
  value: ProductStatus[key],
}));

const productTypeOptions: ColumnFilterItem[] = [
  {
    text: 'Main Product',
    value: ProductType.Main,
  },
  {
    text: 'Add-on',
    value: ProductType.Addons,
  },
  {
    text: 'Add-on Group',
    value: ProductType.ADDON_GROUP,
  },
  {
    text: 'Simple Product',
    value: ProductType.ECOMMERCE,
  },
  {
    text: 'eVoucher',
    value: ProductType.E_VOUCHER,
  },
];

type StatusAction = 'approve' | 'reject';

const getColumns = (
  filter: Record<string, any>,
  organizations: OrganizationModel[],
  actionFunc: (id: string, action: StatusAction) => void,
) => [
  {
    title: 'Product Name',
    dataIndex: 'name',
    ellipsis: {
      showTitle: false,
    },
    sortOrder:
      filter.sortBy === ProductSortBy.NameAscend
        ? 'ascend'
        : filter.sortBy === ProductSortBy.NameDescend
          ? 'descend'
          : null,
    sorter: (sortOrder: string) => sortOrder,
    width: '20%',
    render: (name: string) => (
      <Tooltip placement="topLeft" title={name}>
        <div>
          <Paragraph className="truncate" style={{ fontWeight: 'bold' }}>
            {name}
          </Paragraph>
        </div>
      </Tooltip>
    ),
  },
  {
    title: 'Category',
    dataIndex: 'fullCategory',
    key: 'category',
    width: '25%',
    render: (fullCategory: string, item: ProductModel) => {
      return (
        <div className="flex items-center justify-between">
          <Tooltip placement="topLeft" title={fullCategory}>
            <Text className="max-w-md truncate text-sm font-medium leading-5">{fullCategory}</Text>
          </Tooltip>
          <ProductsCategory item={item} />
        </div>
      );
    },
  },
  {
    title: 'Product Type',
    dataIndex: 'productType',
    filteredValue: filter.productType || null,
    width: '15%',
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => {
      return (
        <FilteredDropdown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          filters={productTypeOptions}
          confirm={confirm}
          selectedAllText="All Product Types"
          clearFilters={clearFilters}
        />
      );
    },
    onFilter: (value: ProductType, record: ProductModel) => {
      return record.productType === value;
    },
    render: (value: ProductType) => (
      <Typography level={9}>{productTypeOptions.find((o) => o.value === value)?.text || value || '-'}</Typography>
    ),
  },
  {
    title: 'Product Status',
    dataIndex: 'status',
    filteredValue: filter.status || null,
    width: '15%',
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => {
      return (
        <FilteredDropdown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          filters={statusOptions}
          confirm={confirm}
          selectedAllText="All Statuses"
          clearFilters={clearFilters}
        />
      );
    },
    onFilter: (value: ProductStatus, record: ProductModel) => {
      return record.status === value;
    },
    render: (status: ProductStatus) => (
      <Tag text={productStatusToTag[status].label} type={productStatusToTag[status].type} />
    ),
  },
  {
    filteredValue: filter.organizationId || null,
    width: '20%',
    ...renderOrgFilterColumn(organizations),
  },
  {
    title: '',
    dataIndex: '',
    width: '15%',
    render: (record: ProductModel) => {
      return (
        <>
          {record.status === ProductStatus.ForReview && (
            <Row spans={[12, 12]}>
              <Button
                type="link"
                title="Approve"
                size="small"
                style={{ color: '#0F6D77' }}
                onClick={() => actionFunc(record.id, 'approve')}
              />
              <Button
                type="link"
                title="Reject"
                size="small"
                style={{ color: '#891A16', marginLeft: 8 }}
                onClick={() => actionFunc(record.id, 'reject')}
              />
            </Row>
          )}
        </>
      );
    },
  },
];

export const ProductsTable = () => {
  const { filter, setFilter, loadData, data, setPagination, pagination, loading } = useProductListContext();
  const { setProductsSelected }: Pick<ProductListMutationStore, 'setProductsSelected'> = useStore(
    (store: ProductListMutationStore) => pick(store, ['setProductsSelected'] as Array<keyof ProductListMutationStore>),
    shallow,
  );
  const { productsSelected } = useGetProductSelected();
  const dataFormatted = useHandleFormattedDataProductList(data);
  const { data: organizations } = useOrganizationListContext();
  const { approveProduct, rejectProduct } = useProductActionContext();
  const { total } = pagination;
  useQueryCategoryList();

  const updateStatusFunc = (id: string, action: StatusAction) => {
    const res = action === 'approve' ? approveProduct(id) : rejectProduct(id);
    if (res) {
      setTimeout(() => {
        setProductsSelected(productsSelected.filter((productId) => productId !== id));
        loadData();
      }, 200);
    }
  };

  useEffect(() => {
    loadData();
  }, [filter]);

  const organizationIds = useMemo(() => organizations.map((org) => org.id), [organizations]);

  const columns = useMemo(
    () => getColumns(filter, organizations, updateStatusFunc),
    [organizations, filter, productsSelected],
  ) as TableColumn<ProductModel>[];

  const handleTableChange = (_pagination: any, filterTable: Record<string, any>, sorter: Sorter) => {
    setFilter({
      ...filter,
      ...filterTable,
      sortBy: sorter.order
        ? sorter.order === 'ascend'
          ? ProductSortBy.NameAscend
          : ProductSortBy.NameDescend
        : undefined,
      // Remap as filter type is `organizationIds`,
      // while table filter is `organizationId`,
      organizationIds: autoRemoveDataIfSelectedAll({
        selectedValues: filterTable.organizationId || [],
        values: organizationIds,
      }),
    });
  };

  return (
    <div className="productTable">
      <Table
        columns={columns}
        data={dataFormatted}
        onChange={handleTableChange}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: productsSelected,
          onChange: (selectedRowKeys: React.Key[], selectedRows: ProductModel[]) => {
            setProductsSelected(selectedRows.map((product: ProductModel) => product.id));
          },
          getCheckboxProps: (record: ProductModel) => ({
            disabled: record.status !== ProductStatus.ForReview,
            name: record.name,
          }),
        }}
        pagination={{
          defaultPageSize: PAGE_SIZE_PER_MODULE.PRODUCTS,
          showSizeChanger: true,
          pageSizeOptions,
          total,
          onChange: (pageIndex, pageSize) => setPagination({ pageIndex, pageSize }),
          current: pagination.pageIndex,
        }}
        loading={loading}
      />
    </div>
  );
};
