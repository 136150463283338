import { Button, FileDragZone, Icon, Modal, Paragraph, Row, Typography, Utils } from '@care/web-ui';
import React, { useEffect, useMemo } from 'react';
import { ManageType } from 'src/enum/manageType.enum';
import { HomeScreenItem } from 'src/model/HomeScreenItem.model';
import InputField from 'src/component/Field/InputField';
import { useForm } from 'react-hook-form';

import './CreateHomeScreenModal.scss';
import SwitchField from 'src/component/Field/SwitchField';
import { RoutingPath } from 'src/component/RoutingPath/RoutingPath';
import { HomeScreenFormModel } from 'src/model/HomeScreenForm.model';
import { UIRoutingType } from 'src/enum/routingType.enum';
import Field from 'src/component/Field/Field';
import { UploadChangeParam, UploadFile } from '@care/web-ui/lib/components/FileDragZone/FileDragZone.typings';
import { homescreenConfigSchema } from './HomeScreenConfigSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { buildLocalUrl } from 'src/util/file.util';
import { ComboBoxItem } from '@care/web-ui/lib/components/ComboBox';

interface Props {
  manageType: ManageType;
  visible: boolean;
  onClose: () => void;
  onSave: (value: HomeScreenItem, isDel?: boolean) => boolean;
  itemDetail: HomeScreenItem;
  isLastItem?: boolean;
  existingItemNames: string[];
  numberOfCurrentVisibleItems?: number;
  maximumVisibleItems?: number;
  customRoutings?: ComboBoxItem[];
  isWebHomeScreen?: boolean;
}

const {
  CommonUtil: { isNull },
} = Utils;

const MAX_SIZE = 5;

export const CreateHomeScreenModal = (props: Props) => {
  const {
    visible,
    onClose,
    manageType,
    onSave,
    itemDetail,
    isLastItem,
    existingItemNames,
    numberOfCurrentVisibleItems,
    maximumVisibleItems,
    customRoutings,
    isWebHomeScreen,
  } = props;

  const initialFormData: HomeScreenFormModel = {
    name: '',
    routingType: customRoutings ? (customRoutings[0].value as UIRoutingType) : UIRoutingType.External,
    routingValue: '',
    localFile: null,
    eventName: '',
    visible: true,
  };

  const dataTestIdPrefix = `manage-${manageType.toLowerCase()}`;

  const { control, reset, handleSubmit, setValue } = useForm<HomeScreenFormModel>({
    defaultValues: initialFormData,
    resolver: yupResolver(
      homescreenConfigSchema(
        itemDetail || {},
        existingItemNames,
        maximumVisibleItems,
        manageType,
        numberOfCurrentVisibleItems,
      ),
    ),
  });

  useEffect(() => {
    if (itemDetail) {
      const castingVal = HomeScreenFormModel.convertFromDTO(itemDetail);
      reset(castingVal);
    }
  }, [itemDetail]);

  const isEdit = !isNull(itemDetail);
  const headerTitle = `${isEdit ? 'Edit' : 'Add'} ${manageType}`;

  const handleCreateOrEdit = () => {
    const submitHandler = handleSubmit((values: HomeScreenFormModel) => {
      const castingVal = HomeScreenFormModel.convertToDTO(values);
      if (onSave(castingVal)) handleCloseModal();
    });
    submitHandler();
  };

  const handleCloseModal = () => {
    onClose();
    reset(initialFormData);
  };

  const handleDelete = () => {
    Modal.confirm({
      title: `Delete ${manageType}?`,
      content: `This ${manageType.toLocaleLowerCase()} will be deleted. This action cannot be undone.`,
      type: 'error',
      okText: 'Delete',
      onOk: () => {
        onSave(itemDetail, true);
        handleCloseModal();
      },
    });
  };

  const defaultCustomRequest = ({ onSuccess }: any) => {
    onSuccess('done');
  };

  const buildDefaultList = () => {
    if (itemDetail) {
      if (itemDetail.localFile) {
        if (!itemDetail.localFile['url']) {
          itemDetail.localFile['url'] = buildLocalUrl(itemDetail.localFile);
        }
        return [itemDetail.localFile];
      }

      if (itemDetail.media) {
        return [itemDetail.media];
      }
    }

    return [];
  };

  return (
    <Modal
      width={700}
      onCancel={handleCloseModal}
      visible={visible}
      title={headerTitle}
      noFooter
      customAttr={{ dataTestId: `${dataTestIdPrefix}-dialog` }}
      content={
        <form className="[&_.care-upload-label]:!text-base">
          <Row gutter={[12, 12]}>
            <InputField
              customAttr={{ dataTestId: `${dataTestIdPrefix}-name-input` }}
              control={control}
              name="name"
              label={`${manageType} Name`}
              placeholder=""
            />

            <Field
              control={control as any}
              name="localFile"
              customAttr={{ dataTestId: `${dataTestIdPrefix}-local-file-fileupload` }}
              render={({ onChange }) => (
                <FileDragZone
                  customRequest={defaultCustomRequest}
                  onChange={(newValue: UploadChangeParam<UploadFile<any>>) => {
                    onChange(newValue.file.originFileObj);
                  }}
                  defaultFileList={buildDefaultList() as any[]}
                  label={`${manageType} Image`}
                  accept=".png, .jpg, .jpeg"
                  multipleUpload={false}
                  maxCount={1}
                  listType="picture"
                  maxSize={MAX_SIZE}
                  singleUploadHideDropzone
                  customAttr={{ dataTestId: `${dataTestIdPrefix}-local-file-fileupload` }}
                >
                  <Button title="Upload Image" type="outline" size="small" className="mb-2 !px-6 !py-1.5" />
                  <Paragraph className="text-center text-sm leading-5 !text-neutral-40">
                    or drag file here to upload
                  </Paragraph>
                </FileDragZone>
              )}
            />

            <RoutingPath control={control} onSetValue={setValue} customRoutings={customRoutings} />

            {!isWebHomeScreen && (
              <InputField
                control={control}
                name="eventName"
                label="Event Name"
                placeholder=""
                customAttr={{ dataTestId: `${dataTestIdPrefix}-event-name-input` }}
              />
            )}

            <div className="visibilitySwitch">
              <Typography display="block" className="care-label">
                {`${manageType} Visibility`}
              </Typography>
              <SwitchField
                name="visible"
                control={control}
                value={true}
                customAttr={{ dataTestId: `${dataTestIdPrefix}-visibility-switch` }}
              />
            </div>

            <div className="groupBtn">
              <div
                className={`deleteItem ${itemDetail === null && 'hidden'} ${isLastItem && 'disable'}`}
                onClick={handleDelete}
              >
                <Icon name="TrashBin" type="outline" color={isLastItem ? '#A5AEB7' : '#D64949'} />
                <Typography weight="semi">Delete</Typography>
              </div>
              <div>
                <Button className="mr-2" type="outline" title="Cancel" onClick={handleCloseModal} />
                <Button title="Save" onClick={handleCreateOrEdit} />
              </div>
            </div>
          </Row>
        </form>
      }
    />
  );
};
