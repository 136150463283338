export type JSONValue = string | number | boolean | null | object | Array<any>;

export const capitalize = (value: string) => {
  return value
    .toLowerCase()
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.substr(1))
    .join(' ');
};

export const stripOutHtmlTags = (value: string): string => {
  return value.replace(/<p>(<br>|&nbsp;|\s)*<\/p>/g, '');
};

export const safeJsonParse = (str: string): JSONValue => {
  try {
    return JSON.parse(str);
  } catch (err) {
    console.error(err);
    return null;
  }
};
