const Routes = {
  Root: '/',
  Refunds: '/refunds',
  Products: '/products',
  PromoCodes: '/promo-code',
  VerificationForms: '/verification-forms',
  Memberships: '/memberships',
  Orders: '/orders',
  Users: '/users',
  Configurations: '/configurations',
  HomeScreenHeader: '/configurations/home-screen-header',
  HomeScreenNotices: '/configurations/home-screen-notices',
  HomeScreenTiles: '/configurations/home-screen-tiles',
  HomeScreenBanners: '/configurations/home-screen-banners',
  WebScreenTiles: '/configurations/web-screen-tiles',
  Organisations: '/organisations',
  OrganisationDetail: '/organisations/:orgId',
  AccountDetail: '/organisations/:orgId/account/:accId',

  Feedbacks: '/feedback',

  //* For DevOps to check if the server is up
  HealthCheck: '/health-check',
};

export default Routes;
