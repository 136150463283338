import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Icon, Sidebar as SideBarMenu } from '@care/web-ui';
import { Routes } from '../constant';
import { useKeycloak } from '@react-keycloak/web';
// import { logout } from "src/util/Auth.util";

const ICON_SIZE = 18;

const menus = [
  {
    title: 'Products',
    icon: (props: any) => <Icon name="Briefcase" {...props} size={ICON_SIZE} />,
    key: Routes.Products,
  },
  {
    title: 'Orders',
    icon: (props: any) => <Icon name="CreditCard" {...props} size={ICON_SIZE} />,
    key: Routes.Orders,
  },
  {
    title: 'Users',
    icon: (props: any) => <Icon name="Users" {...props} size={ICON_SIZE} />,
    key: Routes.Users,
  },
  {
    title: 'Refunds',
    icon: (props: any) => <Icon name="Receipt" {...props} size={ICON_SIZE} />,
    key: Routes.Refunds,
  },
  {
    title: 'Promo Codes',
    icon: (props: any) => <Icon name="Tag" {...props} size={ICON_SIZE} />,
    key: Routes.PromoCodes,
  },
  {
    title: 'Feedback',
    icon: (props: any) => <Icon name="ChatTypingAlt" {...props} size={ICON_SIZE} />,
    key: Routes.Feedbacks,
  },
];

const footerItems = [
  {
    title: 'Organizations',
    icon: (props: any) => <Icon name="School" {...props} size={ICON_SIZE} />,
    key: Routes.Organisations,
  },
  {
    title: 'Configurations',
    icon: (props: any) => <Icon name="Cog" {...props} size={ICON_SIZE} />,
    key: Routes.Configurations,
  },
  {
    title: 'Logout',
    icon: (props: any) => <Icon name="Logout" {...props} />,
    key: 'logout',
  },
];

const logo = <img src="/care-admin.png" alt="care-logo" />;

let lastKey = Routes.Root;

export const Sidebar: React.FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { keycloak } = useKeycloak();

  const [selectedKey, setSelectedKey] = useState(`/${pathname.split('/')[1]}`);

  //handle sidebar tabs when in tab Oders => click a link in orderDetail(Refund detail table) link to Refunds tab
  useEffect(() => {
    const currentMenu = menus.find((m) => pathname.includes(m.key));
    if (currentMenu) {
      setSelectedKey(currentMenu.key);
    }
  }, [pathname]);

  const clickLogout = () => {
    const logoutUrl = keycloak.createLogoutUrl();
    window.location.href = logoutUrl;
  };

  const onMenuChanged = (key: string) => {
    if (key === 'logout') {
      clickLogout();
      // logout();
      return;
    }
    if (key !== lastKey) {
      history.push(key);
      lastKey = key as string;
    }

    setSelectedKey(key);
  };

  return (
    <SideBarMenu
      selectedKey={selectedKey}
      onMenuChanged={onMenuChanged}
      header={logo}
      items={menus}
      footerItems={footerItems}
    />
  );
};
