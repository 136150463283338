import { careNotification, Typography } from '@care/web-ui';
import Utils from '@care/web-ui/lib/utils';
import Constants from '@care/web-ui/lib/constants';
import React, { ReactNode, useEffect, useState, useContext } from 'react';
import { RefundListSort } from 'src/enum/RefundListSort';
import { RefundSummaryResDTO } from 'src/model/dto/Refund.response';
import { RefundModel } from 'src/model/Refund.model';
import {
  approveRefund,
  getAllRefunds,
  rejectRefund,
  getRefundSummary,
  RefundFilter,
  getBulkApproveRefunds,
  approveBulkRefunds,
} from 'src/service/RefundAPI';
import { PAGE_SIZE_PER_MODULE } from 'src/constant/Pagination.constant';
import { RefundType } from 'src/enum/refundType.enum';
import { FilteredDate } from 'src/enum/filteredDate.enum';
import { handleError, handleToastError } from 'src/util/error.util';
import { ApproveBulkRefundsModel } from 'src/model/BulkRefunds.model';

class ContextState {
  refunds: RefundModel[] = [];

  summary: RefundSummaryResDTO;

  sortMode: RefundListSort = RefundListSort.DateDesc;

  setSort!: (mode: RefundListSort) => any;

  loadRefunds!: () => Promise<any>;

  reject!: (id: string, rejectReason: string) => Promise<any>;

  refund!: (id: string, fee: number, amount: number, refundType: RefundType) => Promise<any>;

  handleApproveBulkRefunds!: (ids: string[]) => Promise<any>;

  getBulkRefunds!: (refundIds: string[]) => Promise<ApproveBulkRefundsModel>;

  loading = false;

  filter: RefundFilter;

  setFilter: (filter: RefundFilter) => void;

  totalRefunds?: number;

  setTotalRefunds?: (total: number) => void;

  handleResetFilter?: () => void;

  toggleBulkRefunds: boolean;

  setToggleBulkRefunds: (value: boolean) => void;
}

export const RefundsContext = React.createContext(new ContextState());

interface Props {
  children: ReactNode;
}

const { getPayloadDateString, formatDateString } = Utils.DateUtil;
const { DATE } = Constants.DateConstant;

const currentDate = new Date();
const ISOCurrentDate = formatDateString(currentDate, DATE.ISO_DATE);

export const defaultFilter: RefundFilter = {
  pageIndex: 1,
  pageSize: PAGE_SIZE_PER_MODULE.REFUNDS,
  filterDate: FilteredDate.Today,
  productIds: [],
  fromDate: getPayloadDateString(ISOCurrentDate),
  toDate: getPayloadDateString(ISOCurrentDate, true),
  statuses: [],
  requestedBy: [],
  processTypes: [],
};

const RefundsProvider = ({ children }: React.PropsWithChildren) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [sortMode, setSort] = useState<RefundListSort>(RefundListSort.DateDesc);
  const [refunds, setRefunds] = useState<RefundModel[]>([]);
  const [summary, setSummary] = useState<RefundSummaryResDTO>();
  const [filter, setFilter] = useState<RefundFilter>(defaultFilter);
  const [totalRefunds, setTotalRefunds] = useState<number>();
  const [toggleBulkRefunds, setToggleBulkRefunds] = useState(false);

  const loadRefunds = async (): Promise<any> => {
    try {
      setLoading(true);
      const body = await getAllRefunds({ ...filter }, sortMode);
      setRefunds(body.data);
      setTotalRefunds(body.total);
      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const reject = async (id: string, rejectReason: string): Promise<any> => {
    try {
      setLoading(true);
      await rejectRefund([id], rejectReason);
      getSummary();
      careNotification({
        type: 'success',
        message: (
          <Typography level={8} weight="bold">
            Refund request was rejected
          </Typography>
        ),
        description: <Typography level={7}>The consumer and partner will be informed</Typography>,
        duration: 2,
      });
      return true;
    } catch (error) {
      handleToastError(error, 'Unable to reopen refund request');
      return false;
    } finally {
      setLoading(false);
    }
  };

  const refund = async (id: string, fee: number, amount: number, refundType: RefundType): Promise<any> => {
    try {
      setLoading(true);
      await approveRefund(id, fee, amount, refundType);
      getSummary();
      careNotification({
        type: 'success',
        message: (
          <Typography level={8} weight="bold">
            Refund request was approved
          </Typography>
        ),
        description: <Typography level={7}>Please wait for the payment gateway to disburse the amount</Typography>,
        duration: 2,
      });
      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const getSummary = async () => {
    const res = await getRefundSummary();
    setSummary(res);
  };

  const getBulkRefunds = async (refundIds: string[]): Promise<ApproveBulkRefundsModel> => {
    try {
      setLoading(true);
      const res = await getBulkApproveRefunds(refundIds);
      return res;
    } catch (error) {
      handleError(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSummary();
  }, []);

  const handleResetFilter = () => {
    setFilter(defaultFilter);
  };

  const handleApproveBulkRefunds = async (refundIds: string[]) => {
    try {
      setLoading(true);
      await approveBulkRefunds(refundIds);
      careNotification({
        type: 'success',
        message: (
          <Typography level={8} weight="bold">
            {`${refundIds.length} refund requests approved`}
          </Typography>
        ),
        description: <Typography level={7}>Please wait for the payment gateway to disburse the amount</Typography>,
        duration: 3,
      });
      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <RefundsContext.Provider
      value={{
        refunds,
        loadRefunds,
        loading,
        sortMode,
        setSort,
        reject,
        refund,
        summary,
        filter,
        setFilter,
        totalRefunds,
        setTotalRefunds,
        handleResetFilter,
        toggleBulkRefunds,
        setToggleBulkRefunds,
        getBulkRefunds,
        handleApproveBulkRefunds,
      }}
    >
      {children}
    </RefundsContext.Provider>
  );
};

export const useRefundsContext = () => useContext(RefundsContext);

export default RefundsProvider;
