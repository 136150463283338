import { Utils } from '@care/web-ui';
import { shallow } from '@care/web-ui/lib/store';
import { useEffect } from 'react';
import UserService from 'src/service/User.service';
import { UserListStore, useUserStore } from 'src/zustand-store/user/user.store';

const {
  CommonUtil: { pick },
} = Utils;

export const useFetchUserList = () => {
  const { filter, fetchData: fetchDataQuery } = useUserStore(
    (store: UserListStore) => pick(store, ['filter', 'fetchData'] as Array<keyof UserListStore>),
    shallow,
  );

  const fetchUserList = () => {
    fetchDataQuery({ fetcher: () => UserService.getUsers(filter) });
  };

  useEffect(() => {
    fetchUserList();
  }, [filter]);
  return { fetchUserList };
};
