export enum RefundStatus {
  Requesting = 'requesting',
  InProgress = 'in_progress',
  Success = 'success',
  Partial = 'partial',
  Rejected = 'rejected',
  RejectedByIssuer = 'rejected_by_issuer',
}
export enum RequestRefundType {
  Others = 'others',
}
