import { Checkbox, ComboBox, Row, Typography, Utils } from '@care/web-ui';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ExpandedSelections, Option } from 'src/component/ExpandedSelections/ExpandedSelections';
import { useOrganizationListContext } from 'src/context/OrganizationList.context';

import './CreateOrEditPromoCode.scss';
import { ProductModel } from 'src/model/Product.model';
import { Builder } from 'builder-pattern';
import { ApplyProductItem, Organisation } from 'src/model/dto/CreatePromoCode.request';
import { PromoCodeActionContext } from 'src/context/PromoCodeAction.context';
import { LocationModel } from 'src/model/Location.model';

interface Props {
  organisation: Organisation;
  orgIds: string[];
  handleLocationsChange: (value: Organisation) => void;
}

const {
  CommonUtil: { sumBy, sum, cloneDeep, isUndefined, compact },
} = Utils;

export const ProductsInfo = ({ organisation, orgIds, handleLocationsChange }: Props) => {
  const [applicableProducts, setApplicableProducts] = useState<ApplyProductItem[]>();
  const [initialProducts, setInitialProduct] = useState<ApplyProductItem[]>([]);
  const [immutableOptions, setImmutableOptions] = useState<Option[]>([]);
  const [selectedAll, setSelectedAll] = useState(true);
  const [activeProduct, setActiveProduct] = useState<Option>();
  const [selectedLocs, setSelectedLocs] = useState(['']);
  const [selectedProducts, setSelectedProducts] = useState<string[]>(
    organisation.applyProducts.map((p) => p.productId) || [],
  );
  const [orgId, setOrgId] = useState(organisation.organizationId || '');
  const [locations, setLocations] = useState<LocationModel[]>();
  const [products, setProducts] = useState<ProductModel[]>();

  const { data: organizations } = useOrganizationListContext();
  const { getLocationsByOrgId, getProductsByOrgId } = useContext(PromoCodeActionContext);

  const triggerLoad = async (orgId: string) => {
    const productsRes = await getProductsByOrgId(orgId);
    const locationsRes = await getLocationsByOrgId(orgId);

    setProducts(productsRes);
    setLocations(locationsRes);
  };

  const fileredOrgIds = orgIds.filter((item) => item !== orgId);

  const orgOptions = organizations
    .filter((o) => !fileredOrgIds.includes(o.id))
    .map((org) => ({ text: org.orgName, value: org.id }));

  const numberOfLocations = useMemo(() => sumBy(immutableOptions, (l) => l.nestedList.length), [immutableOptions]);

  const modifyData = (values: any) => {
    const matchProducts = products.filter((p) => p.organizationId === orgId).filter((p) => values.includes(p.id));

    const customProducts = matchProducts.map((p) =>
      Builder(ProductModel, p)
        .locations(
          p.attributes
            .filter((a) => a.name === 'locations')[0]
            .values.map((l) => locations.filter((item) => item.id === l.value)[0]),
        )
        .build(),
    );

    const castingProducts = customProducts.map((c) => ({
      name: c.name,
      value: c.id,
      nestedList: compact(c.locations).map((l) => ({ text: l.name, value: l.id })),
    })) as Option[];

    const castingAvailableProduct = customProducts.map((c) => {
      const matchProduct = organisation.applyProducts.filter((p) => p.productId === c.id)[0];

      const locations =
        isUndefined(matchProduct) || matchProduct.selectAllLocations
          ? compact(c.locations).map((l) => l.id)
          : compact(c.locations)
              .map((l) => l.id)
              .filter((l) => matchProduct.locationIds?.includes(l));

      return Builder(ApplyProductItem)
        .id(matchProduct?.id)
        .selectAllLocations(locations.length === c.locations.length)
        .productId(c.id)
        .locationIds(locations)
        .build();
    });

    setActiveProduct(castingProducts[0]);
    setImmutableOptions(castingProducts);
    setInitialProduct(
      customProducts.map((p) =>
        Builder(ApplyProductItem)
          .productId(p.id)
          .locationIds(compact(p.locations).map((l) => l.id))
          .selectAllLocations(true)
          .build(),
      ),
    );
    setApplicableProducts(castingAvailableProduct);
  };

  const handleOrgChange = async (value: string) => {
    setOrgId(value);
  };

  const handleSelectedProducts = (value: any) => {
    setSelectedProducts(value);
    modifyData(value);

    if (value.length === 0) {
      setOrgId('');
    }
  };

  const updateApplicableProducts = (option: Option) => {
    const castingType = Builder<ApplyProductItem>()
      .id(applicableProducts.filter((p) => p.productId === option.value)[0]?.id)
      .productId(option.value)
      .selectAllLocations(option.isSelectAll)
      .locationIds(option.nestedList.map((n) => n.value as string))
      .build();

    // console.log({ option, applicableProducts, castingType });

    const cloneDeepArr = cloneDeep(applicableProducts);
    const updatedItemIndex = applicableProducts.findIndex((a) => a.productId === castingType.productId);

    cloneDeepArr[updatedItemIndex] = castingType;
    setApplicableProducts(cloneDeepArr);
  };

  const handleSelectedAll = (value: boolean) => {
    setSelectedAll(value);
    const newApplicableProducts = value
      ? initialProducts
      : applicableProducts.map((a) => ({ ...a, locationIds: [], selectAllLocations: false }));
    const newselectedLocs = value ? activeProduct.nestedList.map((s) => s.value as string) : [''];

    setApplicableProducts(newApplicableProducts);
    setSelectedLocs(newselectedLocs);
  };

  useEffect(() => {
    if (orgId) {
      triggerLoad(orgId);
    }
  }, [orgId]);

  useEffect(() => {
    if (products && locations) {
      modifyData(selectedProducts);
    }
  }, [products, locations]);

  useEffect(() => {
    if (activeProduct) {
      const matchProduct = applicableProducts.filter((a) => a.productId === activeProduct.value)[0];
      setSelectedLocs(matchProduct.locationIds);
    }
  }, [activeProduct, applicableProducts]);

  useEffect(() => {
    if (!isUndefined(applicableProducts)) {
      const countedUpdatedLocations = sum(immutableOptions.map((c) => c.nestedList.length));
      const countedCurrentLocations = sum(applicableProducts?.map((c) => c.locationIds.length));

      setSelectedAll(countedCurrentLocations === countedUpdatedLocations);
      handleLocationsChange({ organizationId: orgId, applyProducts: applicableProducts });
    }
  }, [applicableProducts]);

  return (
    <div className="applicableProducts">
      <Row gutter={[8, 18]}>
        <ComboBox
          label="Organization"
          placeholder="Select an organization"
          items={orgOptions}
          value={orgId}
          onChange={handleOrgChange}
        />
        <ComboBox
          label="Applicable Products"
          placeholder="Select a product"
          multiple
          value={selectedProducts}
          items={
            products
              ? products
                  .filter((p) => p.organizationId === orgId)
                  .map((product) => ({ text: product.name, value: product.id }))
              : []
          }
          onChange={handleSelectedProducts}
        />

        {activeProduct && (
          <>
            <div className="separate" />
            <Typography level={6} weight="semi">
              Select applicable locations:
            </Typography>
            <div className="selectAll">
              <Checkbox
                label={`Select all locations that provides the selected products (${numberOfLocations})`}
                value={selectedAll}
                onChange={handleSelectedAll}
              />
            </div>

            <ExpandedSelections
              activeNestedItems={selectedLocs}
              getMutableOption={updateApplicableProducts}
              selectedOption={activeProduct}
              setSelectedOption={setActiveProduct}
              immutableOptions={immutableOptions}
            />
          </>
        )}
      </Row>
    </div>
  );
};
